.subscription-page {
  height: 100%;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
}

.subscription-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 1.16071px;
  margin-bottom: 44px;
}

.subscription-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1.16071px;
  margin-bottom: 24px;
}

.people-img {
  margin-top: 40px;
  margin-bottom: 42px;
}

.subscription-features {

}

.subscription-feature {

}

.feature-number {
  font-weight: 600;
  font-size: 50px;
  line-height: 61px;
  letter-spacing: 1.16071px;
  color: #0DCF9B;
  margin-bottom: 10px;
}

.feature-name {
  font-weight: normal;
font-size: 24px;
line-height: 29px;
letter-spacing: 1.16071px;
margin-bottom: 16px;
}

.feature-description {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.16071px;
  margin-bottom: 40px;
}

.subscription-name {
  margin-top: 80px;
  font-weight: 500;
font-size: 24px;
line-height: 29px;
letter-spacing: 1.16071px;
margin-bottom: 30px;
}

.feature-plan-cost {
  font-weight: normal;
font-size: 32px;
line-height: 39px;
letter-spacing: 1.16071px;
color: #0DCF9B;
}