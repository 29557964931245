.App {
  text-align: center;
  background-color: #1f1f24;
  color: white;
  font-family: Montserrat;
  touch-action: manipulation;
  width: 375px;
  max-width: 375px;
  /* height: 812px; */
  margin: 0 auto;
  padding: 0px;
  overflow: hidden;
  position: relative;
}

.mainPage {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.LogoContainer {
  text-align: left;
  padding-top: 30px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.title {
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 34px;
  /* or 126% */

  display: flex;
  align-items: center;
  letter-spacing: 1.16071px;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #ffffff;
}

.englishTitle {
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 34px;
  /* or 126% */

  display: flex;
  align-items: center;
  letter-spacing: 1.16071px;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #ffffff;
}

.appDescription {
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.16071px;
}

.iPhoneMockups {
  overflow-x: auto;
  white-space: nowrap;
}

.mockup {
  vertical-align: middle;
}

.createBusinessCardButton {
  background: #0dcf9b;
  border-radius: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: white;
  border: none;

  width: 100%;
  height: 50px;
  margin-top: 15px;
  margin-bottom: 40px;
  bottom: 0px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.changeLanguageButton {
  color: #0dcf9b;
  outline: none;
  border: none;
  background-color: #1f1f24;
}

a {
  text-decoration: none;
}
